<script lang="ts" setup>
const { t } = useT();
const isGuest = useIsGuest();
const { open } = useAppModals();
const prepareImgUrl = useImage();
const loginGuard = useLoginGuard();
const { isCanPlay, collectedBalls, BALLS } = useBingoData({ immediate: false });

const bg = `url(${prepareImgUrl(`/nuxt-img/bingo/bg-sidebar.jpg`, {
	format: "avif",
	width: 212,
	height: 74
})}) no-repeat center/cover`;

const handleClick = () => {
	loginGuard({
		success: () => {
			if (isCanPlay.value) {
				open("LazyOModalBingoPlay");
				return;
			}

			navigateTo("/issues/popular-games/");
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

const handleOpenHiwModal = () => {
	open("LazyOModalBingoHowItWorks");
};
</script>

<template>
	<div class="sidebar-bingo" @click="handleClick">
		<AText :size="16" class="btn-info" @click.stop.prevent="handleOpenHiwModal">
			<ASvg name="24/info" filled />
		</AText>

		<NuxtImg
			class="img-bingo"
			src="/nuxt-img/bingo/sidebar.png"
			alt="sidebar bingo"
			format="avif"
			width="111"
			height="111"
		/>
		<div class="content-right">
			<AText class="title" :size="12" :modifiers="['semibold']" as="div">
				<template v-if="isGuest || isCanPlay">{{ t("bingo.sidebar.txt1") }}</template>
				<template v-else>{{ t("bingo.sidebar.txt2") }}</template>
			</AText>
			<MBingoProgress v-if="!isGuest && !isCanPlay" small />
			<template v-else>
				<AButton variant="primary">
					<AText :size="12" :modifiers="['medium']">
						{{ isGuest ? t("bingo.sidebar.button.name1") : t("bingo.sidebar.button.name2") }}
					</AText>
				</AButton>
				<ABadge v-if="!isGuest" variant="info" autosize background="var(--tertiary-300)">
					<AText :size="10" :modifiers="['bold']">
						<span class="text-primary-400">{{ collectedBalls }}</span
						>/{{ BALLS }}
					</AText>
				</ABadge>
			</template>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.sidebar-bingo {
	overflow: hidden;
	background: v-bind(bg);
	border-radius: 8px;
	display: flex;
	align-items: center;
	position: relative;
	--a-badge-info-radius: 24px;
	--a-badge-info-padding: 1px 8px;

	.badge {
		position: absolute;
		bottom: 12px;
		left: 18px;
	}

	.content-right {
		width: 133px;

		.title {
			margin-bottom: 4px;
			line-height: 16px;
			text-shadow: 0px 22px 50px rgba(29, 34, 49, 0.6);
			padding-right: 20px;
		}
	}

	.btn-info {
		display: inline-flex;
		padding: 4px;
		position: absolute;
		top: 0;
		right: 0;
		cursor: pointer;
	}

	.img-bingo {
		width: 74px;
		height: 74px;
	}
}
</style>
